/* Add this to your CSS or create a separate modal.css file */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Additional styling as needed */
/* Add these styles to your existing CSS or create a new stylesheet */
.modal {
  /* Your existing modal styles */

  /* Additional styles for the modal content */
  padding: 20px;
  text-align: center;
}

.modal button {
  /* Button styles */
  background-color: #4caf50; /* Green color */
  padding: 10px 20px; /* Add padding to the button */
  margin-top: 20px; /* Add margin to the top of the button */
  color: white; /* Text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Optional: Add border-radius for rounded corners */
  cursor: pointer; /* Add cursor pointer on hover */
}

/* Optional: Hover effect for the button */
.modal button:hover {
  background-color: #45a049; /* Darker green color on hover */
}


.loader-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  z-index: 1000; /* Ensure it's above other elements */
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}