@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

:root {
    --font-family: 'Rethink Sans', sans-serif;

    /* header */
    --heade-bg-color: #ffffff;
    --nav-items-font: 20px;
    --nav-items-color: #121212;

    /* footer */
    --footer-bg-color: #f2f2f4;

    --newsletters-bg-linear-1: #6345ed;
    --newsletters-bg-linear-2: #bf1980;
    --newsletters-heading-font: 42px;
    --newsletters-desc-font: 18px;
    --newsletters-text-color: #ffffff;
    --browse-more-input-bg: #ffffff;
    --browse-more-input-color: #616161;
    --browse-more-input-font: 16px;
    --browse-more-button-color: #ffffff;
    --browse-more-button-font: 16px;

    --footer-text-color: #14161b;

    --footer-description-font: 14px;
    --footer-heading-font: 18px;
    --footer-news-headline-font: 16px;
    --footer-news-date-font: 13px;

    /* home */
    --future-creation-font: 47px;
    --future-creation-color: #ffffff;

    --print-title-font: 96px; /* this is also being used in print-token page */
    --print-title-color: #14161b; /* this is also being used in print-token page */
    --create-sell-print-font: 45px; /* this is also being used in print-token page */
    --create-sell-print-color: #121212; /* this is also being used in print-token page */
    --learn-more-link-font: 32px;
    --learn-more-link-color: #0995fb;

    --mimaki-main-bg-color: #030303;
    --print-3duj-code-font: 55px;
    --print-3duj-code-color: #fcfdff;
    --pinnacle-of-3d-print-font: 45px;
    --pinnacle-of-3d-print-color: #fefafa;
    --learn-more-button-font: 32px;
    --learn-more-button-text-color: #090909;
    --learn-more-button-bg-color: #d9d9d9;

    /* about */
    --about-main-bg: #000000;

    --about-text-color: #ffffff;
    --about-title-font: 96px;
    --about-sub-title-font: 48px;

    ---aboyt-cards-title-font: 78px;
    ---aboyt-cards-title-color: #f9fafe;
    ---aboyt-cards-desc-font: 42px;
    ---aboyt-cards-desc-color: #fffdfd;

    /* team */
    --linear-bg-1: #6345ed;
    --linear-bg-2: #bf1980;

    --team-headings-font: 128px;
    --team-headings-color: #ffffff;
    --team-members-name-font: 40px;
    --team-members-desc-font: 42px;
    --team-members-details-color: #ffffff;

    --developers-bg-color: #1a1a1c;
    --developers-desc-font: 47px;
    --developers-desc-color: #ffffff;

    --below-the-board-text-font: 20px;
    --below-the-board-text-color: #ffffff;

    /* news */
    --news-linear-bg-1: #6345ed;
    --news-linear-bg-2: #bf1980;

    --news-heading-font: 128px;
    --news-heading-color: #ffffff;

    --news-card-bg-color: #eceaea;
    --about-date-comment-section-bg: #ffffff;
    --about-date-comment-text-font: 15px;
    --about-date-comment-text-color: #616161;
    --about-date-comment-side-line-bg: #6345ed; /*This color is being applied to the element with 10% opacity.*/

    --news-headline-font: 22px;
    --news-headline-color: #31394c;
    --news-description-font: 14px;
    --news-description-color: #616161;
    --news-lint-hover-color: #0995fb;

    /* projects */
    --project-main-bg: #020109;

    --wagmi-desc-font: 47px;
    --wagmi-desc-color: #ffffff;

    --projects-title-font: 47px;
    --projects-title-color: #ffffff;
    --project-desc-font: 32px;
    --project-desc-color: #ffffff;

    /* contact Us */
    --contact-mail-bg: #000000;

    --contact-heading-font: 128px;
    --contact-heading-color: #ffffff;
    --contact-sub-info-font: 32px;
    --contact-sub-info-color: #ffffff;

    --contact-input-font: 22px;
    --contact-input-value-color: #000000;
    --contact-input-placeholder-color: #616161;
    --contact-input-border-color: #444444;
    --contact-input-bg-color: #ffffff;
    --submit-button-font: 20px;
    --submit-btn-text-color: #ffffff;
    --submit-btn-linear-bg-1: #6844e9;
    --submit-btn-linear-bg-2: #c41887;

    /* print-token */
    --print-token-desc-font: 45px;
    --print-token-desc-color: #000000;

    --print-roadmap-dot-bg: #0c0c0c;
    --print-roadmap-lines-bg: #000000;
    --print-roadmap-steps-font: 45px;
    --print-roadmap-steps-color: #000000;

    /* printer */
    --printer-main-bg: #030303;

    --printer-code-font: 96px;
    --printer-code-color: #fcfdff;
    --printer-sub-title-font: 45px;
    --printer-sub-title-color: #fefbfb;
    --printer-heading-font: 96px;
    --printer-heading-color: #fcfdff;
    --printer-desc-benefits-font: 45px;
    --printer-desc-benefits-color: #fefbfb;
}

a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
tt,
ul,
var {
    border: 0;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: none !important;
    padding: 0;
    vertical-align: baseline;
    font-family: 'Rethink Sans', sans-serif;
}

html::-webkit-scrollbar {
    width: 0px;
}

body,
html {
    overscroll-behavior: none;
    background: #b39aff;
}

html {
    scroll-behavior: smooth;
}

.michroma-regular {
    font-family: "Michroma", sans-serif;
    font-style: normal;
}

#country {
    border-right: 12px solid transparent;
}

#country option {
    color: rgba(255, 255, 255, 0.8) !important;
    background: black !important;
    height: 40px !important;
}

input:focus-visible, option:focus-visible, select:focus-visible {
    outline: none !important;
}

.radioInput[type='checkbox'] {
    position: relative;
    cursor: pointer;
    height: 14px;
    width: 14px;
    border-radius: 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.vertical-text {
    transform: rotate(-90deg);
    white-space: nowrap;
}

.studioToast > *{
   text-align: center !important;
}

.Toastify__toast-container{
    width: auto !important;
}

.studioToastMain{
    width: 480px;
}

@media (max-width: 480px){
    .Toastify__toast-container{
        top:1em !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
    }

    .studioToastMain{
        width: 320px !important;
    }
}
