.loader-container {
  height: calc(100vh - 64px);

  img {
    height: 70px;
    width: 70px;
  }
}

@media screen and (max-width: 768px) {
  .loader-container {
    height: calc(100vh - 40px);

    img {
      height: 50px;
      width: 50px;
    }
  }
}