.printer-div {
  height: 1000px;
  background-image: url('../../assets/images/printer.svg');
}

@media screen and (min-width: 2000px) {
  .printer-div {
    height: 1140px !important;
  }
}

@media screen and (max-width: 768px) {
  .printer-div {
    height: 400px;
    background-image: url('../../assets/images/printer-small.svg');
  }
}
