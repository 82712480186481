.specialEditionImg {
  width: 1309px;
}

.project-info {
  img, video {
    max-width: 340px;
  }

  .qrCode {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 768px) {
  .specialEditionImg {
    width: 111%;
  }

  .project-info {
    img, video {
      max-width: 180px;

    }

    .qrCode {
      width: 180px;
      height: 180px;
    }
  }
}
