.newsletters {
  /*
  background: linear-gradient(to bottom right, var(--newsletters-bg-linear-1), var(--newsletters-bg-linear-2));
  */
  background: #ac4eea;

  .browse-more {
    /*
    height: 64px;
    border-radius: 187px;
    */
    width: 540px;

    input {
      font-family: var(--font-family);
      width: 100%;
    }

  }

  /*
  button {
    width: 150px;
    border-radius: 145px;
    height: 42px;
  }
  */

}

@media screen and (max-width: 768px) {
  .newsletters {

    .browse-more {
      height: 48px;
      width: 100%;
      gap: 8px;
    }

    button {
      width: 120px;
      height: 36px;
    }
  }
}

/* Add this to your SCSS file or CSS file */
.captcha-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
}

.overlay {
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style the modal title */
.captcha-modal h2 {
  font-size: 24px;
  margin-bottom: 16px;
  text-align: center;
}

/* Style the modal close button */
.captcha-modal button {
  background-color: #bebec1;
  color: #080101;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Hover effect on the close button */
.captcha-modal button:hover {
  background-color: #8f8d8d;
}
