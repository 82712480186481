.png{
    background-repeat: no-repeat;
    width: 100%;
    height: 50%;
}

.Water{
    background-color: rgba(241, 237, 237, 0.475);
    padding: 0.4rem;
    border-radius: 10px;
}

.Info{
    position: relative;
}