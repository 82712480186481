.news-desc > a {
  color: var(--news-lint-hover-color);
}

.news-desc-points {
  text-indent: -1em;
  padding-left: 2em;

  &::before {
    content: '\2022';
    margin-right: 0.5em;
  }
}
