.printer-div {
  height: 1200px;
  background-image: url('../../assets/images/printer-large.svg');
}

.benefit-points {
  text-indent: -1em;
  padding-left: 1em;

  &::before {
    content: '\2022';
    margin-right: 0.5em;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .printer-div {
    height: 400px;
    background-image: url('../../assets/images/printer-small.svg');
  }
}
