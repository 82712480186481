.print-roadMap {
  .dotted-line {
    flex: 1 1;
    height: 1px;
    position: relative;
    width: 45px;
  }

  .dotted-line::after {
    content: "";
    position: absolute;
    top: 0;
    left: -30px;
    right: -30px;
    border-bottom: 12px dotted var(--print-roadmap-dot-bg);
    transform: rotate(90deg);
  }

  .dotted-line::before {
    content: "";
    position: absolute;
    top: 4px;
    height: 2px;
    width: 200px;
    left: 20px;
    background: var(--print-roadmap-lines-bg);
  }

  .middle-dotted-line:before {
    right: 20px;
    left: auto;
  }

  .step-details {
    position: absolute;
    width: 210px;
    top: -10px;
    left: 240px;
  }

  .step-details.middle-step {
    left: auto;
    right: 150px;
    width: 280px;
  }

  .step-details.img-side-text {
    top: 50%;
    left: 180px;
    width: 360px;
    transform: translateY(-50%);
  }

  .step-details.last-step {
    top: -10px;
    width: 340px;
  }

  .step-details.last-step-left {
    top: 5px;
    left: auto;
    right: 100px;
  }

  .dot {
    position: absolute;
    height: 12px;
    width: 12px;
    background: var(--print-roadmap-dot-bg);
    border-radius: 50px;
    left: 220px;
  }

  .middle-dot {
    right: 220px;
    left: auto;
  }

  .side-line_with-dots {
    position: absolute;
    right: -132px;
    width: 120px;
    height: 2px;
    background: var(--print-roadmap-lines-bg);
    top: 20px;

    &:before, &:after {
      content: "";
      position: absolute;
      height: 12px;
      width: 12px;
      border-radius: 50px;
      background: var(--print-roadmap-dot-bg);
      top: -4px;
    }

    &:after {
      right: 0;
    }
  }

  .roundedDotBorder {
    position: absolute;
    min-width: 180px;
    height: 190px;
    top: 40px;
    left: -175px;
  }
}

@media screen and (max-width: 768px) {
  .print-roadMap {

    .step-img {
      height: 84px;
      width: 84px;
    }

    .dotted-line {
      width: 10px;
    }

    .dotted-line::after {
      border-bottom: 8px dotted var(--print-roadmap-dot-bg);
    }

    .dotted-line::before {
      top: 3px;
      width: 65px;
      left: 10px;
    }

    .middle-dotted-line:before {
      right: 10px;
      left: auto;
    }

    .step-details {
      width: 40px;
      top: -20px;
      left: 90px;
    }

    .step-details.middle-step {
      left: auto;
      right: 85px;
      width: 82px;
    }

    .step-details.img-side-text {
      left: 100px;
      width: 60px;
      top: 55%;
    }

    .step-details.last-step {
      width: 70px;
    }

    .step-details.last-step-left {
      top: -25px;
      left: auto;
      right: 60px;
    }

    .dot {
      height: 8px;
      width: 8px;
      left: 75px;
    }

    .middle-dot {
      right: 75px;
      left: auto;
    }

    .side-line_with-dots {
      right: 0;
      width: 0;
      top: 65px;

      &:before, &:after {
        height: 8px;
        width: 8px;
        top: -25px;
      }

      &:after {
        right: 0;
        display: none;
      }
    }

    .roundedDotBorder {
      height: 100px;
      left: -100px;
      top: 82px;
    }
  }
}
