/* Rectangle 120 */
.box {
    position: relative;
    width: 400px;
    height: 240px;
    left: calc(50% - 400px/2.01 - 1px);
    background: linear-gradient(110.66deg, #FF0000 2.26%, #FF00B3 47.05%, #0004FF 91.83%);
    border-radius: 25px;

    @media (max-width: 768px) {
        width: 300px;
        height: 180px;
        left: calc(50% - 300px/2.01 - 1px);
    }
}

.letter {
    position: relative;
    top: 42%;
    text-align: center;
    font-weight: 700;
    font-size: 108px;
    line-height: 50px;
    letter-spacing: -0.03em;

    @media (max-width: 768px) {
        font-size: 72px;
    }
}

.para {
    text-align: center;
    margin: 0 auto;
    
    @media (max-width: 768px) {
        width: 80%;
        margin: 0 auto;
        padding: 0;
    }
}

.img {
    background-repeat: no-repeat;
    border-radius: 32px 32px;
    position: relative;
    width: 260px;
    height: auto;
    left: calc(50% - 260px/2.01 - 1px);

    @media (max-width: 768px) {
        width: 50%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.img1 {
    position: relative;
    width: 420px;
    left: calc(50% - 420px/2.10 - 1px);

    @media (max-width: 768px) {
        width: 50%;
        left: 50%;
        transform: translateX(-50%);
    }
}
